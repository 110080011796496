.pagination > li > a {
  background-color: #1b2337;
  color: white;
  border: solid 1px #12141d !important;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: white;
  background-color: #1b2337;
  border-color: #12141d !important;
}

.pagination > .active > a {
  color: white;
  background-color: #1b2337 !important;
  border: solid 1px #12141d !important;
}

.pagination > .active > a:hover {
  background-color: #1b2337 !important;
  border: solid 1px #12141d !important;
}

/* .headshot {
  height: '20px' !important;
} */
